const {
  REACT_APP_API_GATEWAY,
  NODE_ENV,
  REACT_APP_CLARITY_ID,
  REACT_APP_CUSTOMER_ID,
  REACT_APP_CMS_SOURCE,
} = process.env;

// Using proxy in development so REACT_APP_API_GATEWAY is blank
// Do not check REACT_APP_API_GATEWAY in development
// To change REACT_APP_API_GATEWAY in development, go to package.json and change "proxy" value

if (NODE_ENV !== 'development' && NODE_ENV !== 'test') {
  if (!REACT_APP_API_GATEWAY) {
    throw new Error('[ENV] REACT_APP_API_GATEWAY is missing!');
  }
  if (!REACT_APP_CUSTOMER_ID) {
    throw new Error('[ENV] REACT_APP_CUSTOMER_ID is missing!');
  }
}

interface IConfig {
  apiGateway?: string;
  clarityId?: string;
  gridImageView: {
    [key: string]: {
      amount: number;
      colCount: number;
      default?: boolean;
    };
  };
  autoretouchView: {
    itemsPerPage: number;
  };
  defaultCustomerId: string;
  cmsSource: string,
}

const config: IConfig = {
  apiGateway: REACT_APP_API_GATEWAY,
  clarityId: REACT_APP_CLARITY_ID,
  gridImageView: {
    'S (3x3)': {
      amount: 9,
      colCount: 3,
    },
    'M (4x3)': {
      amount: 12,
      colCount: 4,
    },
    'L (6x4)': {
      amount: 24,
      colCount: 6,
      default: true,
    },
  },
  autoretouchView: {
    itemsPerPage: 6,
  },
  defaultCustomerId: REACT_APP_CUSTOMER_ID ?? '',
  cmsSource: REACT_APP_CMS_SOURCE ?? 'attribute-service',
};

export function getDefaultGridSize(): string {
  return Object
    .keys(config.gridImageView)
    .find(x => config.gridImageView[x].default === true) 
    || 'L (6x4)';
}

export default config;
