import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { RF_HOME_PATH } from 'app/routes';

// Override RouteProps -> set "component" is required
interface IPublicRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  restricted?: boolean;
}

const PublicRoute = ({
  component: Component,
  restricted = false,
  ...rest
}: IPublicRouteProps) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && restricted ? (
          <Redirect to={RF_HOME_PATH} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
