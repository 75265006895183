import { Modal } from "antd";

export function showError() {
  Modal.warning({
    title: 'Notification',
    content:
      'Sorry for the inconvenience. Please contact your supervisor for assistance.',
    okText: 'OK',
    centered: true,
  });
}

export function showCustomError(message: string) {
  Modal.warning({
    title: 'Failed',
    content: message,
    okText: 'OK',
    centered: true,
  });
}