import { Spin } from "antd";
import LoadingContainer from "./LoadingContainer";

const LoadingSpinner = () => (
    <LoadingContainer>
        <Spin />
    </LoadingContainer>
);

export default LoadingSpinner;
  