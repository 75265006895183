/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import { useTranslation } from 'react-i18next';
import MainPage from './pages/__main__';
import config from './config';

const clarityScript =
  config.clarityId &&
  `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "$clarityId");`
  .replace(
    '$clarityId',
    config.clarityId,
  );

export function App() {
  const { i18n } = useTranslation();

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - PATE"
        defaultTitle="PATE Review Tools"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="PATE Review Tools" />
        {clarityScript && (<script type="text/jvascript">{clarityScript}</script>)}
      </Helmet>
        <MainPage />
      <GlobalStyle />
    </BrowserRouter>
  );
}
