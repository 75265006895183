import { IDataCache } from "./IDataCache";

interface CacheItem {
  key: string;
  time: number;
  content: any;
}

const DB_NAME = 'rfdb';
const CMS_STORE_NAME = 'CMS';

const queryDebouncer: Record<string, Promise<any> | null> = {};

// IndexedDB implementation for Caching
export default class IndexedDbDataCache implements IDataCache {
  private _expireTime: number; // miliseconds

  private _db: IDBDatabase | null;

  constructor(expireTimeInSeconds: number, onComplete?: (message?: string) => void) {
    this._expireTime = expireTimeInSeconds * 1000;
    this._db = null;

    this.initialize(onComplete);
  }

  public async getData(cacheKey: string, fetchFunction: () => Promise<any>): Promise<any> {
    var cached = await this.getCache(cacheKey);
    if (cached) return cached;

    if (queryDebouncer[cacheKey]) {
      await queryDebouncer[cacheKey];
      return await this.getData(cacheKey, fetchFunction);
    }
    
    queryDebouncer[cacheKey] = fetchFunction();
    var data = await queryDebouncer[cacheKey];
    this.setCache(cacheKey, data);
    queryDebouncer[cacheKey] = null;

    return data;
  }

  // just clear them all
  public async invalidateAllCache(): Promise<void> {
    console.log("Cache invalidating...");
    await this.clearDb();
  }

  private async getCache(key: string): Promise<any | null> {
    let cache = await this.getCMSCache(key);
    if (!cache || !cache.content) return null;

    if (cache.time + this._expireTime < new Date().getTime()) {
      this.removeCMSCache(key); // no need for await here
      return null;
    }
    
    // cache ok
    return cache.content;
  }

  private async setCache(key: string, content: any): Promise<void> {
    let cacheItem: CacheItem = {
      key,
      content,
      time: new Date().getTime(),
    };
    try {
      const trans = this.getCMSTransaction('readwrite');
      trans.put(cacheItem);
      // we don't care if it store successfull or not here
    } catch {
      console.error("Cache engine failed to persist!");
    }
  }

  private initialize(onComplete?: (message?: string) => void) {
    if (this._db) return;

    const dbOpenRequest = window.indexedDB.open(DB_NAME, 1);

    dbOpenRequest.onerror = (event) => {
      console.error('Error loading database', event);
      if (onComplete) onComplete('Dataabse open failed!');
    }
    dbOpenRequest.onsuccess = (e) => {
      this._db = dbOpenRequest.result;
      if (onComplete) {
        console.log("DB init success");
        onComplete();
      }
    }
    dbOpenRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      console.log("DB upgrading...")
      let db = (event.target as IDBOpenDBRequest).result;
      db.createObjectStore(CMS_STORE_NAME, { keyPath: "key" });
    }
  }

  private clearDb(): Promise<boolean> {
    if (!this._db) return Promise.resolve(false);
    // now clear all the itemss
    var clearReq = this._db.transaction(CMS_STORE_NAME, 'readwrite').objectStore(CMS_STORE_NAME).clear();

    return new Promise(r => {
      clearReq.onsuccess = () => {
        r(true);
      }
      clearReq.onerror = () => {
        r(false);
      }
    })
  }

  private getCMSTransaction(mode: IDBTransactionMode): IDBObjectStore {
    if (this._db === null) throw new Error("DB not initialized");
    const trans = this._db.transaction(CMS_STORE_NAME, mode);

    trans.onabort = (event: any) => {
      const error = event.target.error; // DOMException
      if (error.name === 'QuotaExceededError') {
        this.clearDb();
      }
    }

    return trans.objectStore(CMS_STORE_NAME);
  }
  private async getCMSCache(key: string): Promise<CacheItem | null> {
    const trans = this.getCMSTransaction('readonly');

    return new Promise(r => {
      const request = trans.get(key);
      request.onsuccess = () => {
        r(request.result);
      }
      request.onerror = () => {
        r(null);
      }
    })
  }
  private async removeCMSCache(key: string): Promise<void> {
    const trans = this.getCMSTransaction('readwrite');

    return new Promise(r => {
      const request = trans.delete(key);
      request.onsuccess = () => {
        r();
      }
      request.onerror = () => {
        r();
      }
    })
  }
}