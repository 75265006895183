/**
 * Asynchronously loads the component for AttributeClassificationPage
 */

import { lazyLoad } from 'utils/loadable';

export const AttributeQualityCheckPage = lazyLoad(
  () => import('./index'),
  module => module.default,
);
