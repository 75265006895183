import { useIsAuthenticated } from '@azure/msal-react';
import RoleContext from 'app/layouts/RFLayout/components/RoleContext';
import { RF_HOME_PATH, LOGIN_PATH } from 'app/routes';
import React, { useContext } from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

// Override RouteProps -> set "component" is required
interface IPrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  roles?: string[];
}

const PrivateRoute = ({
  component: Component,
  roles,
  ...rest
}: IPrivateRouteProps) => {
  const isAuthenticated = useIsAuthenticated();
  const { roles: userRoles } = useContext(RoleContext);

  const hasRequiredRole = !roles || (userRoles && roles.some(r => userRoles.includes(r)));
  if (isAuthenticated && userRoles != null && !hasRequiredRole) {
    return <Redirect to={RF_HOME_PATH}/>
  }

  return (
    // TODO: enforce user route here, redirect to HOME if have no permission
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={LOGIN_PATH} />
        )
      }
    />
  );
};

export default PrivateRoute;
