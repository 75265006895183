import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalConfig, loginRequest } from 'app/config/auth';

const pca = new PublicClientApplication(msalConfig);
let expirationTime = 0;

export async function getTokens() {
  const account = pca.getActiveAccount();

  if (account != null) {
    const unixTime = Math.floor(Date.now() / 1000);
    const request = {
      ...loginRequest,
      account,
      forceRefresh: unixTime > expirationTime,
    };
    const idToken = await pca
      .acquireTokenSilent(request)
      .then(response => {
        expirationTime = response.idTokenClaims['exp'];
        return {
          idToken: response.idToken,
          accessToken: response.accessToken,
        };
      })
      .catch(error => {
        // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
        if (error instanceof InteractionRequiredAuthError) {
          pca.acquireTokenRedirect(request);
        }
        console.log(error);
        return null;
      });

    return idToken;
  }

  return null;
}

export const msalInstance = pca;
