import { useIsAuthenticated } from "@azure/msal-react";
import CMSClient from "app/cms/CMSClient";
import config from "app/config";
import { EApiEndpoint } from "app/enums/ApiEndpoint";
import { useCallback, useEffect, useState } from "react";
import { showError } from "utils/ant-utils";
import api from "utils/api";
import IndexedDbDataCache from "utils/data-cache/IndexedDbDataCache";

interface CmsInfoResponse {
  endpoint: string;
  token: string;
}

export default function useCmsClient(): CMSClient | null {
  const isAuthenticated = useIsAuthenticated();
  const [cmsClient, setCmsClient] = useState<CMSClient | null>(null);
  const [cmsConfig, setCmsConfig] = useState<CmsInfoResponse | null>(null);

  const queryCmsInfo = useCallback(async () => {
    if (!isAuthenticated) return;

    let rp = await api.get<CmsInfoResponse>(EApiEndpoint.CMSConfigurationRequest);
    if (rp.data) {
      setCmsConfig(rp.data);
    } else {
      setCmsConfig(null);
      showError(); // time to show an error for reviewer as we failed to fetch CMS Client info.
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (cmsConfig == null) {
      queryCmsInfo(); // fire and forget
      return;
    }

    if (cmsClient) return;

    const newClient = new CMSClient({
      uri: cmsConfig.endpoint,
      token: cmsConfig.token,
      cacher: new IndexedDbDataCache(6 * 60 * 60, cacheInitComplete), // 6 hours
      // @ts-ignore
      mode: config.cmsSource,
    })

    // IndexedDB need time to initialize
    function cacheInitComplete(message?: string) {
      setCmsClient(newClient);
      console.log("Created new CMS Client.");
    }
  }, [queryCmsInfo, cmsConfig, cmsClient]);

  return cmsClient;
}