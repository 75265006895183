import { Attribute, AttributeType, SelectionAttribute } from "./types";

export function traverseComplexAttribute(attribute: Attribute<AttributeType>, path: string[])
: AttributeType | null {
  if (!attribute) return null;
  if (path[0] !== attribute.key) return null;
  if (path.length === 1) return attribute.type;

  return traverseToLeaf(attribute.type, path.slice(1));
}

function traverseToLeaf(attributeType: AttributeType, path: string[]): AttributeType | null {
  if (!path || path.length === 0) return attributeType;
  if (attributeType.__typename !== 'ComplexAttribute') return null;

  const complexProps = attributeType.complexAttributeProperties ?? [];

  for(const prop of complexProps) {
    if (prop.key === path[0]) {
      const result = traverseToLeaf(prop.type, path.slice(1));
      if (result) return result;
    }
  }

  return null;
}

export function getOptions(attributeNode: any) {
  if (!attributeNode || !attributeNode.type?.selectionOptionGroup) return null;
  
  return attributeNode.type.selectionOptionGroup.selectionOptions ?? [];
}

export function getOptionsFromType(attributeType: AttributeType | null) {
  if (!attributeType || !(attributeType as SelectionAttribute).selectionOptionGroup) return null;
  
  return (attributeType as SelectionAttribute).selectionOptionGroup.selectionOptions ?? [];
}

// Flatten the complex attributes
export function flattenComplexAttribute(attributes: object, attribute: any, prefixs: string[], originalAttribute: any) {
  // if (!attribute || !attribute.key || attribute.key === '-') {
  //   return;
  // }
  if (!attribute || !attribute.identifier) return;
  
  const currentPrefixs = prefixs.concat(attribute.identifier);
  if (attribute?.type?.__typename === 'ComplexAttribute') {
    (attribute.type.complexAttributeProperties || []).forEach((x: any) => {
      flattenComplexAttribute(attributes, x, currentPrefixs, originalAttribute);
    })
  } else {
    // got a leaf node, time to add it
    attribute.relation = originalAttribute.relation; // add the parent's relation
    attribute.isHidden = attribute.isInternal ?? false; // hide it from general public
    attribute.isInternal = true; // hide it from general public
    attribute.textGenerationType = originalAttribute.textGenerationType;
    attribute.title = attribute.name || attribute.title;
    attributes[currentPrefixs.join('.')] = attribute;
  }
}
