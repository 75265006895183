import {
  LOGIN_PATH,
  ATTRIBUTE_CLASSIFICATION_PATH,
  EXPERT_ATTRIBUTE_CLASSIFICATION_PATH,
  BATCH_REVIEW_PATH,
  RF_HOME_PATH,
  VARIANT_REVIEW_PATH,
  VARIANT_REVIEW_EXPERT_PATH,
  MATERIAL_REVIEW_PATH,
  MATERIAL_REVIEW_EXPERT_PATH,
  TEXT_REVIEW_PATH,
  TEXT_REVIEW_EXPERT_PATH,
  FINAL_QUALITY_CHECK_PATH,
  FINAL_QUALITY_CHECK_EXPERT_PATH,
  CARE_LABELS_REVIEW_PATH,
  CARE_LABELS_REVIEW_EXPERT_PATH,
  CLEARING_TOOL_PATH,
  ATTRIBUTE_QUALITY_PATH,
  ATTRIBUTE_QUALITY_EXPERT_PATH,
  RESOLVER_PATH,
  RESOLVER_EXPERT_PATH,
  REPROCESS_PATH,
  REPROCESS_EXPERT_PATH,
  WORKFLOW_INSPECT_PATH,
} from 'app/routes';

import { Route, Switch } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useDispatch, useSelector } from 'react-redux';
import { selectMain } from './slice/selectors';
import { useMainSlice } from './slice';
import config from 'app/config';

import PrivateRoute from 'app/components/PrivateRoute';
import PublicRoute from 'app/components/PublicRoute';
import LoadingSpinner from './components/LoadingSpinner';

import { LoginPage } from '../Login/Loadable';
import { NotFoundPage } from '../NotFound/Loadable';
import { AttributeClassificationPage } from '../AttributeClassificationPage/Loadable';
import { AttributeClassificationExpertPage } from '../AttributeClassificationExpertPage/Loadable';
import { BatchReviewPage } from '../BatchReview/Loadable';
import { DashboardRFPage } from '../DashboardRF/Loadable';
import { VariantReviewPage } from '../VariantReviewPage/Loadable';
import { VariantReviewExpertPage } from '../VariantReviewExpertPage/Loadable';
import { MaterialReviewPage } from '../MaterialReviewPage/Loadable';
import { MaterialReviewExpertPage } from '../MaterialReviewExpertPage/Loadable';
import { TextReviewPage } from '../TextReviewPage/Loadable';
import { TextReviewExpertPage } from '../TextReviewExpertPage/Loadable';
import { FinalQualityCheckPage } from '../FinalQualityCheckPage/Loadable';
import { ExpertQualityCheckPage } from '../ExpertQualityCheckPage/Loadable';
import { CareLabelsReviewPage } from '../CareLabelsReviewPage/Loadable';
import { CareLabelsReviewExpertPage } from '../CareLabelsReviewExpertPage/Loadable';
import { FinalQualityClearingPage } from '../FinalQualityClearingPage/Loadable';
import { AttributeQualityCheckPage } from '../AttributeQualityCheckPage/Loadable';
import { AttributeQualityCheckExpertPage } from '../AttributeQualityCheckExpertPage/Loadable';
import { ResolverPage } from '../ResolverPage/Loadable';
import { ResolverExpertPage } from '../ResolverExpertPage/Loadable';
import { ReprocessPage } from '../ReprocessPage/Loadable';
import { ReprocessExpertPage } from '../ReprocessExpertPage/Loadable';
import { WorkflowInspectorPage } from '../WorkflowInspectorPage/Loadable';

import CMSClientContext from 'app/cms/CMSClientContext';
import useCmsClient from 'app/hooks/useCmsClient';
import { useEffect, useState } from 'react';
import { getTokens } from 'utils/auth/msalProvider';
import { message as AntdMessage } from "antd";
import jwt_decode from "jwt-decode";
import RoleContext from 'app/layouts/RFLayout/components/RoleContext';
import { 
  AttributeQualityExpertPermissions,
  AttributeQualityPermissions,
  BatchReviewPermissions,
  CareLabelReviewExpertPermissions,
  CareLabelReviewPermissions,
  ClearingPermissions,
  ManualClassificationExpertPermissions,
  ManualClassificationPermissions,
  MaterialReviewExpertPermissions,
  MaterialReviewPermisions,
  QualityCheckExpertPermissions,
  QualityCheckPermissions,
  ReprocessExpertPermissions,
  ReprocessPermissions,
  ResolverExpertPermissions,
  ResolverPermissions,
  TextReviewExpertPermissions,
  TextReviewPermissions,
  VariantReviewExpertPermissions,
  VariantReviewPermissions 
} from 'app/enums/Permissions';

export default function MainPage() {
  const dispatch = useDispatch();
  const { actions } = useMainSlice();
  const { customerId } = useSelector(selectMain);
  const { instance, accounts, inProgress } = useMsal();
  const [userRoles, setUserRoles] = useState<string[] | null>(null);

  const cmsClient = useCmsClient();

  // we need an active account to use. Just select first one
  const account = instance.getActiveAccount();
  if (account == null && accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
  }

  // this was part of the game when we need to show Customer Selection box when it is not selected.
  // will be updated in another task
  if (!customerId) {
    dispatch(actions.setCustomerId(config.defaultCustomerId));
  }

  const parseAndSetUserRole = (accessToken: string) => {
    var decoded: any = jwt_decode(accessToken);
    const roles: string = decoded.extension_roles ?? '';
    console.log(roles)
    setUserRoles(roles.split(','));
  }

  const handleFirstLogin = async () => {
    if (accounts.length > 0) {
      getTokens().then(tokens => {
        if (!tokens?.accessToken) {
          AntdMessage.error("User token is not valid, please try again!");
        } else {
          parseAndSetUserRole(tokens?.accessToken);
        }
      }).catch(err => {
        console.error(err);
        AntdMessage.error("Unable to get user's token, please contact administrator!");
      })
    }
  }

  useEffect(() => {
    handleFirstLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  if (inProgress !== InteractionStatus.None) {
    // MSAL is working on something, render loading page until all things settled.
    return <LoadingSpinner />;
  }

  return (
    <CMSClientContext.Provider value={cmsClient}>
    <RoleContext.Provider value={{roles: userRoles}}>
    <Switch>
      <PrivateRoute
        exact
        roles={BatchReviewPermissions}
        path={BATCH_REVIEW_PATH}
        component={BatchReviewPage}
      />
      <PrivateRoute
        exact
        path={RF_HOME_PATH}
        component={DashboardRFPage} />
      <PrivateRoute
        exact
        roles={ManualClassificationPermissions}
        path={ATTRIBUTE_CLASSIFICATION_PATH}
        component={AttributeClassificationPage}
      />
      <PrivateRoute
        exact
        roles={ManualClassificationExpertPermissions}
        path={EXPERT_ATTRIBUTE_CLASSIFICATION_PATH}
        component={AttributeClassificationExpertPage}
      />
      <PrivateRoute
        exact
        roles={VariantReviewPermissions}
        path={VARIANT_REVIEW_PATH}
        component={VariantReviewPage}
      />
      <PrivateRoute
        exact
        roles={VariantReviewExpertPermissions}
        path={VARIANT_REVIEW_EXPERT_PATH}
        component={VariantReviewExpertPage}
      />
      <PrivateRoute
        exact
        roles={MaterialReviewPermisions}
        path={MATERIAL_REVIEW_PATH}
        component={MaterialReviewPage}
      />
      <PrivateRoute
        exact
        roles={MaterialReviewExpertPermissions}
        path={MATERIAL_REVIEW_EXPERT_PATH}
        component={MaterialReviewExpertPage}
      />
      <PrivateRoute
        exact
        roles={TextReviewPermissions}
        path={TEXT_REVIEW_PATH}
        component={TextReviewPage}
      />
      <PrivateRoute
        exact
        roles={TextReviewExpertPermissions}
        path={TEXT_REVIEW_EXPERT_PATH}
        component={TextReviewExpertPage}
      />
      <PrivateRoute
        exact
        roles={QualityCheckPermissions}
        path={FINAL_QUALITY_CHECK_PATH}
        component={FinalQualityCheckPage}
      />
      <PrivateRoute
        exact
        roles={QualityCheckExpertPermissions}
        path={FINAL_QUALITY_CHECK_EXPERT_PATH}
        component={ExpertQualityCheckPage}
      />
      <PrivateRoute
        exact
        roles={CareLabelReviewPermissions}
        path={CARE_LABELS_REVIEW_PATH}
        component={CareLabelsReviewPage}
      />
      <PrivateRoute
        exact
        roles={CareLabelReviewExpertPermissions}
        path={CARE_LABELS_REVIEW_EXPERT_PATH}
        component={CareLabelsReviewExpertPage}
      />
      <PrivateRoute
        exact
        roles={ClearingPermissions}
        path={CLEARING_TOOL_PATH}
        component={FinalQualityClearingPage}
      />
      <PrivateRoute
        exact
        roles={AttributeQualityPermissions}
        path={ATTRIBUTE_QUALITY_PATH}
        component={AttributeQualityCheckPage}
      />
      <PrivateRoute
        exact
        roles={AttributeQualityExpertPermissions}
        path={ATTRIBUTE_QUALITY_EXPERT_PATH}
        component={AttributeQualityCheckExpertPage}
      />
      <PrivateRoute
        exact
        roles={ResolverPermissions}
        path={RESOLVER_PATH}
        component={ResolverPage}
      />
      <PrivateRoute
        exact
        roles={ResolverExpertPermissions}
        path={RESOLVER_EXPERT_PATH}
        component={ResolverExpertPage}
      />
      <PrivateRoute
        exact
        roles={ReprocessPermissions}
        path={REPROCESS_PATH}
        component={ReprocessPage}
      />
      <PrivateRoute
        exact
        roles={ReprocessExpertPermissions}
        path={REPROCESS_EXPERT_PATH}
        component={ReprocessExpertPage}
      />
      { /* Administration page */ }
      <PrivateRoute
        exact
        path={WORKFLOW_INSPECT_PATH}
        component={WorkflowInspectorPage}
      />
      { /* Other pages */ }
      <PublicRoute
        exact
        path={LOGIN_PATH}
        component={LoginPage}
        restricted
      />
      <Route component={NotFoundPage} />
    </Switch>
    </RoleContext.Provider>
    </CMSClientContext.Provider>
  );
}
