import { translationsJson } from "locales/i18n";
import { GetBlackListResponse } from "./types";

export default interface ICmsQuery {
  setLanguage(lang: string): void;
  reset(): void;
  queryOptions: (optionName: string) => Promise<any>;
  queryArticleAttributesDetail: (category: string, subCategory: string, articleType: string) => Promise<any>;
  queryArticleType: (identifier: string) => Promise<any>;
  queryBlacklists: (name: string) => Promise<GetBlackListResponse[]>;
  ///
  queryMaterial: (attributeName: string) => Promise<any>;
}

export const SUPPORTED_LOCALES = Object.keys(translationsJson);
export const COMPLEX_ATTRIBUTE_EXCEPTIONS = [
  'clothingMaterial', 'sellingPoints', 'attributeGenericCareLabelTextile', 'attributeGenericNoticeableFeatures'
]