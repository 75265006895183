export enum EApiEndpoint {
  BatchReviewRequest = '/review/regular/batch',
  BatchReviewResponse = '/review/regular/batch/complete',
  ManualClassificationRequest = '/classification/regular/manual',
  ManualClassificationResponse = '/classification/regular/manual/complete',
  ExpertReviewRequest = '/review/regular/expert',
  ExpertReviewResponse = '/review/regular/expert/complete',
  PostProductionReviewRequest = '/review/regular/post-production',
  PostProductionReviewResponse = '/review/regular/post-production/complete',
  PostProductionExpertReviewRequest = '/review/expert/post-production',
  PostProductionExpertReviewResponse = '/review/expert/post-production/complete',
  ManualAttributeClassificationRequest = '/classification/regular/manual-attribute',
  ManualAttributeClassificationResponse = '/classification/regular/manual-attribute/complete',
  ManualAttributeClassificationExpertRequest = '/classification/expert/manual-attribute',
  ManualAttributeClassificationExpertResponse = '/classification/expert/manual-attribute/complete',
  ManualAttributeClassificationSkip = '/classification/regular/manual-attribute/skip',
  ManualAttributeClassificationNotApplicable = '/classification/regular/manual-attribute/set-as-not-applicable',
  ManualAttributeClassificationExpertNotApplicable = '/classification/expert/manual-attribute/set-as-not-applicable',
  ManualAttributeClassificationGetArticles = '/classification/regular/manual-attribute/priority-article-type-statistic',
  ManualAttributeClassificationGetArticlesExpert = '/classification/expert/manual-attribute/priority-article-type-statistic',
  ManualAttributeClassificationGetByArticleId = '/classification/regular/manual-attribute/workflow-by-id',
  ManualAttributeClassificationGetByArticleIdExpert = '/classification/expert/manual-attribute/workflow-by-id',
  VariantReviewRequest = '/review/regular/product-variant',
  VariantReviewResponse = '/review/regular/product-variant/complete',
  VariantReviewExpertRequest = '/review/expert/product-variant',
  VariantReviewExpertResponse = '/review/expert/product-variant/complete',
  VariantReviewSkip = '/review/regular/product-variant/skip',
  VariantReviewGetArticles = '/review/regular/product-variant/priority-article-type-statistic',
  VariantReviewGetArticlesExpert = '/review/expert/product-variant/priority-article-type-statistic',
  VariantReviewGetByArticleId = '/review/regular/product-variant/workflow-by-id',
  VariantReviewGetByArticleIdExpert = '/review/expert/product-variant/workflow-by-id',
  MaterialReviewRequest = '/review/regular/material',
  MaterialReviewResponse = '/review/regular/material/complete',
  MaterialReviewExpertRequest = '/review/expert/material',
  MaterialReviewExpertResponse = '/review/expert/material/complete',
  MaterialReviewSkip = '/review/regular/material/skip',
  MaterialReviewGetArticles = '/review/regular/material/priority-article-type-statistic',
  MaterialReviewGetArticlesExpert = '/review/expert/material/priority-article-type-statistic',
  MaterialReviewGetByArticleId = '/review/regular/material/workflow-by-id',
  MaterialReviewGetByArticleIdExpert = '/review/expert/material/workflow-by-id',
  TextReviewRequest = '/review/regular/text',
  TextReviewResponse = '/review/regular/text/complete',
  TextReviewExpertRequest = '/review/expert/text',
  TextReviewExpertResponse = '/review/expert/text/complete',
  TextReviewSkip = '/review/regular/text/skip',
  TextReviewGetArticles = '/review/regular/text/priority-article-type-statistic',
  TextReviewGetArticlesExpert = '/review/expert/text/priority-article-type-statistic',
  TextReviewGetByArticleId = '/review/regular/text/workflow-by-id',
  TextReviewGetByArticleIdExpert = '/review/expert/text/workflow-by-id',
  TextReviewCancel = '/review/regular/text/cancel',
  QualityCheckRequest = '/review/regular/product-quality',
  QualityCheckResponse = '/review/regular/product-quality/complete',
  QualityCheckExpertRequest = '/review/expert/product-quality',
  QualityCheckExpertResponse = '/review/expert/product-quality/complete',
  QualityCheckSaveProgress = '/review/regular/product-quality/save',
  QualityCheckRenewLock = '/review/regular/product-quality/renew',
  QualityCheckSkip = '/review/regular/product-quality/skip',
  QualityCheckMarkSeparated = '/review/regular/product-quality/mark-separated',
  QualityCheckSendToStep = '/review/regular/product-quality/send-to-step',
  QualityCheckCancel = '/review/regular/product-quality/cancel',
  QualityCheckGetArticles = '/review/regular/product-quality/priority-article-type-statistic',
  QualityCheckGetArticlesExpert = '/review/expert/product-quality/priority-article-type-statistic',
  QualityCheckGetByArticleId = '/review/regular/product-quality/workflow-by-id',
  QualityCheckGetByArticleIdExpert = '/review/expert/product-quality/workflow-by-id',
  CareLabelsRequest = '/review/regular/care-label',
  CareLabelsResponse = '/review/regular/care-label/complete',
  CareLabelsExpertRequest = '/review/expert/care-label',
  CareLabelsExpertResponse = '/review/expert/care-label/complete',
  CareLabelsSkip = '/review/regular/care-label/skip',
  CareLabelsGetArticles = '/review/regular/care-label/priority-article-type-statistic',
  CareLabelsGetArticlesExpert = '/review/expert/care-label/priority-article-type-statistic',
  CareLabelsGetByArticleId = '/review/regular/care-label/workflow-by-id',
  CareLabelsGetByArticleIdExpert = '/review/expert/care-label/workflow-by-id',
  ClearingRequest = '/review/clearing/product-quality',
  ClearingGetArticles = '/review/clearing/product-quality/priority-article-type-statistic',
  ClearingResponse = '/review/clearing/product-quality/complete',
  AttributeQualityCheckRequest = '/review/regular/attributes-quality',
  AttributeQualityCheckResponse = '/review/regular/attributes-quality/complete',
  AttributeQualityCheckExpertRequest = '/review/expert/attributes-quality',
  AttributeQualityCheckExpertResponse = '/review/expert/attributes-quality/complete',
  AttributeQualityCheckSaveProgress = '/review/regular/attributes-quality/save',
  AttributeQualityCheckRenewLock = '/review/regular/attributes-quality/renew',
  AttributeQualityCheckSkip = '/review/regular/attributes-quality/skip',
  AttributeQualityCheckMarkSeparated = '/review/regular/attributes-quality/mark-separated',
  AttributeQualityCheckCancel = '/review/regular/attributes-quality/cancel',
  AttributeQualityCheckGetArticles = '/review/regular/attributes-quality/priority-article-type-statistic',
  AttributeQualityCheckGetArticlesExpert = '/review/expert/attributes-quality/priority-article-type-statistic',
  AttributeQualityCheckGetByArticleId = '/review/regular/attributes-quality/workflow-by-id',
  AttributeQualityCheckGetByArticleIdExpert = '/review/expert/attributes-quality/workflow-by-id',
  ResolverRequest = '/resolver/regular/',
  ResolverResponse = '/resolver/regular/complete',
  ResolverExpertRequest = '/resolver/expert/',
  ResolverExpertResponse = '/resolver/expert/complete',
  ResolverSaveProgress = '/resolver/regular/save',
  ResolverRenewLock = '/resolver/regular/renew',
  ResolverSkip = '/resolver/regular/skip',
  ResolverGetArticles = '/resolver/regular/priority-article-type-statistic',
  ResolverGetArticlesExpert = '/resolver/expert/priority-article-type-statistic',
  ResolverCancel = '/resolver/regular/cancel',
  ResolverGetByArticleId = '/resolver/regular/workflow-by-id',
  ResolverGetByArticleIdExpert = '/resolver/expert/workflow-by-id',
  ReprocessRequest = '/review/regular/reprocessing',
  ReprocessResponse = '/review/regular/reprocessing/complete',
  ReprocessExpertRequest = '/review/expert/reprocessing',
  ReprocessExpertResponse = '/review/expert/reprocessing/complete',
  ReprocessSaveProgress = '/review/regular/reprocessing/save',
  ReprocessRenewLock = '/review/regular/reprocessing/renew',
  ReprocessSkip = '/review/regular/reprocessing/skip',
  ReprocessCancel = '/review/regular/reprocessing/cancel',
  ReprocessMarkSeparated = '/review/regular/reprocessing/mark-separated',
  ReprocessGetArticles = '/review/regular/reprocessing/priority-article-type-statistic',
  ReprocessGetArticlesExpert = '/review/expert/reprocessing/priority-article-type-statistic',
  ReprocessGetByArticleId = '/review/regular/reprocessing/workflow-by-id',
  ReprocessGetByArticleIdExpert = '/review/expert/reprocessing/workflow-by-id',

  CMSConfigurationRequest = "/cms/configuration",
  ProcessTextGeneration = "/image-process/text-generation",
  ToolByPriorityStatistic = "/data/tool-priority-statistic",
  WorkflowContextRequest = "/workflow/product/execution/{id}/context",
  WorkflowPropertiesRequest = "/workflow/product/properties",
  ReviewerComment = "/review/{review-type}/comments",
  ReviewerPerformance = "/data/personal-performance",
  WorkflowContextRequest2 = "/workflow/product",
  WorkflowByArticleRequest = "/workflow/product/from-article"
}