import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { mainSaga } from './saga';
import { MainState } from './types';

export const initialState: MainState = {
  customerId: '',
  cacheIndex: 0,
  // empty for now
};

const slice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setCustomerId(state, action: PayloadAction<string>) {
      return {
        ...state,
        customerId: action.payload,
      };
    },
    increaseCache(state) {
      return {
        ...state,
        cacheIndex: state.cacheIndex + 1,
      }
    }
  },
});

export const { actions: mainActions } = slice;

export const useMainSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: mainSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useMainSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
