import api from "utils/api";
import ICmsQuery, { COMPLEX_ATTRIBUTE_EXCEPTIONS, SUPPORTED_LOCALES } from "../ICmsQuery";
import config from "app/config";
import { flattenComplexAttribute, getOptionsFromType, traverseComplexAttribute } from "../utils";

const URL_PREFIX = "/attributes";
const ROUTES = {
  Options: URL_PREFIX + '/selection-options',
  Attribute: URL_PREFIX + '/attribute',
  ArticleType: URL_PREFIX + '/article-type',
  ArticleTypeConstraints: URL_PREFIX + '/article-constraints',
  Blacklist: URL_PREFIX + '/blacklist',
}

export default class AttributeServiceClient implements ICmsQuery {
  private lang: string = 'en';

  setLanguage(lang: string): void {
    this.lang = lang;
  }
  reset(): void {
    // nothing to do here
  }
  async queryOptions(optionName: string): Promise<any> {
    const url = `${ROUTES.Options}/${config.defaultCustomerId}/${optionName}`;
    return await this.makeRequest(url);
  }
  async queryArticleAttributesDetail(category: string, subCategory: string, articleType: string): Promise<any> {
    const url = `${ROUTES.ArticleTypeConstraints}/${config.defaultCustomerId}/${category}|${subCategory}|${articleType}`;
    var data = await this.makeRequest(url);

    const attributes: Record<string, any> = {};
    (data as any[]).forEach(x => {
      const articleAttribute = x;
      const attribute = articleAttribute.attribute;
      attribute.key = attribute.key || attribute.identifier;
      // some cases we still got null values, make sure to skip it.
      if (attribute && attribute.key) {
        if (COMPLEX_ATTRIBUTE_EXCEPTIONS.includes(attribute.key)) {
          // console.log('Ignoring attribute: ' + attribute.key)
          // return;
          attribute.isInternal = true;
          console.log('Mark as internal: ' + attribute.key)
        }
        // extra values for variants
        attribute.relation = articleAttribute.relation;
        attribute.variantDefining = articleAttribute.variantDefining;
        attribute.variantDependent = articleAttribute.variantDependent;
        attribute.mapsTo = articleAttribute.mapsTo;
        attribute.textGenerationType = articleAttribute.textGenerationType;
        attributes[attribute.key] = attribute;

        // add more attributes for complex scenario
        if (attribute?.type?.__typename === 'ComplexAttribute') {
          if (!COMPLEX_ATTRIBUTE_EXCEPTIONS.includes(attribute.key)) { //don't flatten these
            flattenComplexAttribute(attributes, attribute, [], attribute);
          }
        }
      }
    })

    console.log(`Article data fetched, ${Object.keys(attributes).length} attribute(s)`)
    return attributes;
  }
  async queryArticleType(identifier: string): Promise<any> {
    const url = `${ROUTES.ArticleType}/${config.defaultCustomerId}/${identifier}`;
    return await this.makeRequest(url);
  }
  async queryMaterial(attributeName: string): Promise<any> {
    const url = `${ROUTES.Attribute}/${config.defaultCustomerId}/${attributeName}`;
    const attribute: any = await this.makeRequest(url);

    // dereference
    const materials = getOptionsFromType(traverseComplexAttribute(attribute, "clothingMaterial.material.composition.material".split('.')));
    const locations = getOptionsFromType(traverseComplexAttribute(attribute, "clothingMaterial.material.location".split('.')));

    return [locations, materials];
  }
  async queryBlacklists(name: string): Promise<any[]> {
    const url = `${ROUTES.Blacklist}/${config.defaultCustomerId}/${name}`;
    const data: any = await this.makeRequest(url);
    return data.blacklists;
  }

  private getDefaultHeaders() {
    const langs = SUPPORTED_LOCALES
      .filter(x => x === this.lang)
      .concat(SUPPORTED_LOCALES.filter(x => x !== this.lang));
    return {
      'x-language': langs,
    }
  }

  private async makeRequest(url: string) {
    let result = await api.get(url, { headers: this.getDefaultHeaders() });
    return result.data;
  }
}