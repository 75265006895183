import { gql } from "@apollo/client";

// fragment define of attribute
export const AttributeFragment = `
fragment AttributeConstraintResult on ArticleTypeAttribute {
  variantDefining
  variantDependent
  relation
  textGenerationType
  mapsTo {
    ... on Attribute {
      key
    }
  },
  attribute {
    identifier
    key
    name
    description
    shortDescription
    blacklists {
      ...Blacklist
    }
    type {
      ...StringAttribute
      ...NumberAttribute
      ...SelectionAttribute
      ...ComplexAttribute
    }
    isNullable
    isInternal
    isArray
    arrayMinItems
    arrayMaxItems
    arrayEnforceDistinctItems
  }
}

fragment ComplexAttribute on ComplexAttribute {
  __typename
  complexAttributeProperties {
    ...ComplexAttributeProperty
    type {
      ...StringAttribute
      ...NumberAttribute
      ...SelectionAttribute
      ... on ComplexAttribute {
        __typename
        complexAttributeProperties {
          ...ComplexAttributeProperty
          type {
            ...StringAttribute
            ...NumberAttribute
            ...SelectionAttribute
            ... on ComplexAttribute {
              __typename
              complexAttributeProperties {
                ...ComplexAttributeProperty
                type {
                  ...StringAttribute
                  ...NumberAttribute
                  ...SelectionAttribute
                  ... on ComplexAttribute {
                    __typename
                    complexAttributeProperties {
                      ...ComplexAttributeProperty
                      type {
                        ...StringAttribute
                        ...NumberAttribute
                        ...SelectionAttribute
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

fragment ComplexAttributeProperty on ComplexAttributeProperty {
  identifier
  key
  name
  blacklists {
    ...Blacklist
  }
  isNullable
  isArray
  arrayMinItems
  arrayMaxItems
  isInternal
}

fragment SelectionAttribute on SelectionAttribute {
  __typename
  selectionOptionGroup {
    identifier
  }
}

fragment NumberAttribute on NumberAttribute {
  __typename
  minValue: minValue
  maxValue: maxValue
  multipleOf: multipleOf
}

fragment StringAttribute on StringAttribute {
  __typename
  minLength
  maxLength
  regularExpression
}

fragment Blacklist on Blacklist {
  title
  contents
  useWordBoundaries
  ignoreCase
}`;

export const GetArticleDetailByCombinedIdentifier = gql`
query GetArticleDetail($identifier: String!, $cursor: String) {
  articleTypeAttributesConnection (
    where: { articleTypeIdentifier: $identifier },
    first: 1000
    after: $cursor
  ) {
    edges {
      node {
        ...AttributeConstraintResult  
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
${AttributeFragment}
`;

export const GetMaterialDetail = gql`
query GetMaterialDetail($attributeKey: String!) {
  attribute(where: {key: $attributeKey}) {
    identifier
    key
    type {
      ... on ComplexAttribute {
        complexAttributeProperties {
          key
          type {
            ... on ComplexAttribute {
              complexAttributeProperties {
                key
                type {
                  ... on ComplexAttribute {
                    complexAttributeProperties {
                      key
                      type {
                        ... on SelectionAttribute {
                          id
                          selectionOptionGroup {
                            identifier
                            selectionOptions(first:100) {
                              identifier
                              key
                              title
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on SelectionAttribute {
                    id
                    selectionOptionGroup {
                      identifier
                      selectionOptions(first:100) {
                        identifier
                        key
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GetSelectionOption = gql`
query GetSelectionOption($identifier: String, $cursor: String) {
  selectionOptionsConnection(
    where: {selectionOptionGroups_some: {identifier: $identifier}}
    first: 1000
    after: $cursor
  ) {
    edges {
      node {
        identifier
        key
        title
        sampleImage {
          fileName
          url
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`

export const GetArticleType = gql`
query GetArticleType($identifier: String!) {
  articleType(
    where: {identifier: $identifier}
  ) {
    isSupported
    key
    name
    singularName
  }
}
`

export const GetBlacklist = gql`
query GetBlacklist($key: String!) {
  blacklists(where: {key: $key}) {
    id
    ignoreCase
    title
    useWordBoundaries
    contents
  }
}
`