import axios from 'axios';
import config from 'app/config';
import { getTokens } from './auth/msalProvider';

const instance = axios.create({
  baseURL: config.apiGateway,
});

instance.interceptors.request.use(
  async request => {
    const tokens = await getTokens();
    if (!tokens) {
      throw new Error('Unable to retrieve tokens!');
    }

    const token = tokens.accessToken;

    if (token) {
      if (!request.headers) request.headers = {};
      request.headers.Authorization = `Bearer ${token}`;

      // for development only, will be replaced by API Gateway!
      request.headers['x-reviewer-id'] = 'e7c0d8f2-479c-4315-87d0-806da3347d06';
      request.headers['x-reviewer-name'] = 'local-developer';
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  },
);

export default instance;
