import { IDataCache } from "utils/data-cache/IDataCache";
import ICmsQuery from "./ICmsQuery";
import GraphQLServiceClient from "./services/GraphQLServiceClient";
import AttributeServiceClient from "./services/AttributeServiceClient";

export interface CMSClientOptions {
  uri?: string;
  token?: string;
  cacher?: IDataCache;
  mode?: 'hygraph' | 'attribute-service'
}

export interface ArticleQuery {
  category: string;
  subCategory: string;
  articleType: string;
  language: string;
}

export default class CMSClient {
  private cacher?: IDataCache;
  private service: ICmsQuery;

  constructor(options: CMSClientOptions) {
    if (options.mode === 'hygraph') {
      console.log("CMS: Using 3rd Party Service")
      this.service = new GraphQLServiceClient(options);
    } else {
      console.log("CMS: Using Attribute Service")
      this.service = new AttributeServiceClient();
    }

    this.cacher = options.cacher;
  }

  public async getSelectionOptions(optionKey: string, language: string) {
    const KEY = `OPTION_${optionKey}`;
    this.service.setLanguage(language);

    if (this.cacher) {
      return this.cacher.getData(KEY, () => this.service.queryOptions(optionKey));
    } else {
      return this.service.queryOptions(optionKey);
    }
  }

  public async getArticleAttributes(query: ArticleQuery) {
    const KEY = `ARTICLE_${query.category}_${query.subCategory}_${query.articleType}`;
    this.service.setLanguage(query.language);
    
    if (this.cacher) {
      return this.cacher.getData(KEY, () => this.service.queryArticleAttributesDetail(
        query.category,
        query.subCategory,
        query.articleType
      ));
    } else {
      return this.service.queryArticleAttributesDetail(
        query.category,
        query.subCategory,
        query.articleType
      );
    }
  }

  public async getMaterialDetail(attributeName: string, language: string) {
    const KEY = `MATERIAL_${attributeName}`;
    this.service.setLanguage(language);
    
    if (this.cacher) {
      return this.cacher.getData(KEY, () => this.service.queryMaterial(attributeName));
    } else {
      return this.service.queryMaterial(attributeName);
    }
  }

  public async getBlacklists(name: string, language: string) {
    const KEY = `BLACKLIST_${name}`;
    this.service.setLanguage(language);
    
    if (this.cacher) {
      return this.cacher.getData(KEY, () => this.service.queryBlacklists(name));
    } else {
      return this.service.queryBlacklists(name);
    }
  }

  public async invalidateCache(): Promise<void> {
    if (this.cacher) {
      await this.cacher.invalidateAllCache();
    }
    this.service.reset();
  }

  public async getArticleType(identifier: string, language: string) {
    const KEY = `ARTICLE_DATA_${identifier}`;
    this.service.setLanguage(language);

    if (this.cacher) {
      return this.cacher.getData(KEY, () => this.service.queryArticleType(identifier));
    } else {
      return this.service.queryArticleType(identifier);
    }
  }
}