import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import ICmsQuery, { COMPLEX_ATTRIBUTE_EXCEPTIONS, SUPPORTED_LOCALES } from "../ICmsQuery";
import { CMSClientOptions } from "../CMSClient";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { GetArticleTypeResponse, GetBlackListResponse, PageInfo, QueryConnectionResponse } from "../types";
import { GetArticleDetailByCombinedIdentifier, GetArticleType, GetBlacklist, GetMaterialDetail, GetSelectionOption } from "./GqlQueries";
import { flattenComplexAttribute, getOptionsFromType, traverseComplexAttribute } from "../utils";

export default class GraphQLServiceClient implements ICmsQuery {
  private client: ApolloClient<NormalizedCacheObject>;
  private token: string;
  private lang: string = "en";
  
  constructor(options: CMSClientOptions) {
    const httpLink = new HttpLink({
      uri: options.uri,
    });
    const retryLink = new RetryLink();
    const authLink = setContext((_, { headers }) => {
      // build fallback locales
      const langs = SUPPORTED_LOCALES
        .filter(x => x === this.lang)
        .concat(SUPPORTED_LOCALES.filter(x => x !== this.lang));

      return {
        headers: {
          ...headers,
          'gcms-locales': langs.join(','),
          authorization: `Bearer ${this.token}`
        }
      }
    });
    const client = new ApolloClient({
      link: authLink.concat(retryLink).concat(httpLink),
      cache: new InMemoryCache(),
    });
    this.client = client;
    this.token = options.token ?? "";
  }

  setLanguage(lang: string) {
    this.lang = lang;
  }

  reset() {
    this.client.resetStore();
  }

  async queryOptions(identifier: string) {
    const result: any[] = [];
    let paging: PageInfo | null = null;
    let loopCount = 0;
    
    do {
      let rp = await this.client.query({
        fetchPolicy: "no-cache", // skip apollo built-in cache
        query: GetSelectionOption,
        variables: {
          identifier,
          cursor: paging?.endCursor ?? undefined
        },
      });

      const rpData = rp.data.selectionOptionsConnection as QueryConnectionResponse<any>;
      paging = rpData.pageInfo;
      result.push(...rpData.edges.map(x => x.node));
    } while((++loopCount < 10) &&  (paging.hasNextPage ?? false))

    // console.log(`Article data fetched after ${loopCount} loop(s), ${Object.keys(result).length} attribute(s)`)
    return result;
  }

  async queryArticleAttributesDetail(category: string, subCategory: string, articleType: string) {
    let paging: PageInfo | null = null;
    const result: Record<string, any> = {};
    let loopCount = 0;

    do {
      let rp = await this.client.query({
        fetchPolicy: "no-cache",
        query: GetArticleDetailByCombinedIdentifier,
        variables: {
          identifier:`${category}|${subCategory}|${articleType}`,
          cursor: paging?.endCursor ?? undefined,
        }
      });
  
      let rpData = rp.data.articleTypeAttributesConnection as QueryConnectionResponse<any>;
      paging = rpData.pageInfo;

      let attributes = {};
      rpData.edges.forEach(x => {
        const articleAttribute = x.node;
        const attribute = articleAttribute.attribute;
        // some cases we still got null values, make sure to skip it.
        if (attribute && attribute.key) {
          if (COMPLEX_ATTRIBUTE_EXCEPTIONS.includes(attribute.key)) {
            // console.log('Ignoring attribute: ' + attribute.key)
            // return;
            attribute.isInternal = true;
            console.log('Mark as internal: ' + attribute.key)
          }
          // extra values for variants
          attribute.relation = articleAttribute.relation;
          attribute.variantDefining = articleAttribute.variantDefining;
          attribute.variantDependent = articleAttribute.variantDependent;
          attribute.mapsTo = articleAttribute.mapsTo;
          attribute.textGenerationType = articleAttribute.textGenerationType;
          attributes[attribute.key] = attribute;
  
          // add more attributes for complex scenario
          if (attribute?.type?.__typename === 'ComplexAttribute') {
            if (!COMPLEX_ATTRIBUTE_EXCEPTIONS.includes(attribute.key)) { //don't flatten these
              flattenComplexAttribute(attributes, attribute, [], attribute);
            }
          }
        }
      })
      
      Object.assign(result, attributes);
    } while((++loopCount < 10) && (paging.hasNextPage ?? false))

    console.log(`Article data fetched after ${loopCount} loop(s), ${Object.keys(result).length} attribute(s)`)
    return result;
  }

  async queryMaterial(attributeKey: string) {
    let result = await this.client.query({
      fetchPolicy: "no-cache",
      query: GetMaterialDetail,
      variables: {
        attributeKey
      }
    });
    
    // dereference
    let attribute: any = result.data?.attribute
    const materials = getOptionsFromType(traverseComplexAttribute(attribute, "clothingMaterial.material.composition.material".split('.')));
    const locations = getOptionsFromType(traverseComplexAttribute(attribute, "clothingMaterial.material.location".split('.')));

    return [locations, materials];
  }

  async queryArticleType(identifier: string): Promise<GetArticleTypeResponse | null> {
    let result = await this.client.query({
      fetchPolicy: "no-cache", // skip apollo built-in cache
      query: GetArticleType,
      variables: {
        identifier,
      },
    });
    return result.data.articleType;
  }

  async queryBlacklists(key: string): Promise<GetBlackListResponse[]> {
    let result = await this.client.query({
      fetchPolicy: "no-cache", // skip apollo built-in cache
      query: GetBlacklist,
      variables: {
        key,
      },
    });
    return result.data.blacklists;
  }
}