export const HOME_PATH = '/v1';
export const LOGIN_PATH = '/login';
export const SINGLE_IMAGE_POST_PERSPECTIVE_PATH = '/single-image-perspective';
export const SINGLE_IMAGE_CATEGORIZATION_PATH = '/single-image-subject';
export const GRID_IMAGE_REVIEW_PATH = '/grid-image-review';
export const AUTORETOUCH_REVIEW_PATH = '/autoretouch-review';
export const EXPERT_REVIEW_PATH = '/expert-review';
export const AUTORETOUCH_EXPERT_REVIEW_PATH = '/autoretouch-expert-review';

export const RF_HOME_PATH = '/';
export const BATCH_REVIEW_PATH = '/batch-review';
export const ATTRIBUTE_CLASSIFICATION_PATH = '/attribute-classification';
export const EXPERT_ATTRIBUTE_CLASSIFICATION_PATH = '/attribute-classification/expert';
export const VARIANT_REVIEW_PATH = '/variant-review';
export const VARIANT_REVIEW_EXPERT_PATH = '/variant-review/expert';
export const MATERIAL_REVIEW_PATH = '/material-review';
export const MATERIAL_REVIEW_EXPERT_PATH = '/material-review/expert';
export const TEXT_REVIEW_PATH = '/text-review';
export const TEXT_REVIEW_EXPERT_PATH = '/text-review/expert';
export const FINAL_QUALITY_CHECK_PATH = '/quality-check';
export const FINAL_QUALITY_CHECK_EXPERT_PATH = '/quality-check/expert';
export const CARE_LABELS_REVIEW_PATH = '/care-labels-review';
export const CARE_LABELS_REVIEW_EXPERT_PATH = '/care-labels-review/expert';
export const CLEARING_TOOL_PATH = '/clearing';
export const ATTRIBUTE_QUALITY_PATH = '/attribute-quality';
export const ATTRIBUTE_QUALITY_EXPERT_PATH = '/attribute-quality/expert';
export const RESOLVER_PATH = '/resolver';
export const RESOLVER_EXPERT_PATH = '/resolver/expert';
export const REPROCESS_PATH = '/reprocess';
export const REPROCESS_EXPERT_PATH = '/reprocess/expert';

export const WORKFLOW_INSPECT_PATH = '/workflow/inspect'