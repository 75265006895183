import { RootStateKeyType } from '../types/injector-typings';
import {
  createSlice as createSliceOriginal,
  SliceCaseReducers,
  CreateSliceOptions,
  AnyAction,
} from '@reduxjs/toolkit';
import { Dispatch, Reducer, useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { runSaga, Saga, stdChannel } from 'redux-saga';

/* Wrap createSlice with stricter Name options */

/* istanbul ignore next */
export const createSlice = <
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends RootStateKeyType,
>(
  options: CreateSliceOptions<State, CaseReducers, Name>,
) => {
  return createSliceOriginal(options);
};

/* Setup reducer & saga to use locally (tied to component life cycle) */
export function useReducerAndSaga<T>(
  reducer: Reducer<T, AnyAction>, 
  initialState: T, 
  rootSaga: Saga<any[]>,
  contextData: any, // main state object
): [T, Dispatch<AnyAction>]
{
  const [state, internalDispatch] = useReducer(reducer, initialState);

  // setup saga side effect channel and hooked dispatch
  const channel = useMemo(() => stdChannel(), []);
  const dispatch = useCallback((a) => {
    setImmediate(channel.put, a);
    internalDispatch(a);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // flexible getState function, prevent hoisting
  const env = useRef(state);
  env.current = state;
  const getState = useCallback(() => env.current, []);

  // store context data as a dynamic approach
  const context = useRef(contextData);
  context.current = contextData;

  // spin up a new saga to handle side effects
  useEffect(() => {
    const task = runSaga({ channel, dispatch, getState, context }, rootSaga);
    return () => task.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, dispatch];
}