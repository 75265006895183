export enum ReviewToolPermission {
  All = "rev.all",
  Batch = "rev.batch",
  Manual = "rev.manual-attribute",
  ManualExpert = "rev.manual-attribute.expert",
  ManualAll = "rev.manual-attribute.all",
  Variant = "rev.product-variant",
  VariantExpert = "rev.product-variant.expert",
  VariantAll = "rev.product-variant.all",
  Material = "rev.material",
  MaterialExpert = "rev.material.expert",
  MaterialAll = "rev.material.all",
  CareLabel = "rev.care-label",
  CareLabelExpert ="rev.care-label.expert",
  CareLabelAll ="rev.care-label.all",
  AttributeQuality = "rev.attr-quality",
  AttributeQualityExpert ="rev.attr-quality.expert",
  AttributeQualityAll ="rev.attr-quality.all",
  Text = "rev.text",
  TextExpert = "rev.text.expert",
  TextAll = "rev.text.all",
  ProductQuality = "rev.product-quality",
  ProductQualityExpert = "rev.product-quality.expert",
  ProductQualityAll = "rev.product-quality.all",
  Clearing = "rev.clearing",
  Resolver = "rev.resolver",
  ResolverExpert = "rev.resolver.expert",
  ResolverAll = "rev.resolver.all",
  Reprocess = "rev.reprocessing",
  ReproccessExpert = "rev.reprocessing.expert",
  ReprocessAll = "rev.reprocessing.all",
}

export const BatchReviewPermissions = [ReviewToolPermission.All, ReviewToolPermission.Batch];
export const ManualClassificationPermissions = [ReviewToolPermission.All, ReviewToolPermission.Manual, ReviewToolPermission.ManualAll];
export const ManualClassificationExpertPermissions = [ReviewToolPermission.All, ReviewToolPermission.ManualExpert, ReviewToolPermission.ManualAll];
export const VariantReviewPermissions = [ReviewToolPermission.All, ReviewToolPermission.Variant, ReviewToolPermission.VariantAll];
export const VariantReviewExpertPermissions = [ReviewToolPermission.All, ReviewToolPermission.VariantExpert, ReviewToolPermission.VariantAll];
export const MaterialReviewPermisions = [ReviewToolPermission.All, ReviewToolPermission.Material, ReviewToolPermission.MaterialAll];
export const MaterialReviewExpertPermissions = [ReviewToolPermission.All, ReviewToolPermission.MaterialExpert, ReviewToolPermission.MaterialAll];
export const CareLabelReviewPermissions = [ReviewToolPermission.All, ReviewToolPermission.CareLabel, ReviewToolPermission.CareLabelAll];
export const CareLabelReviewExpertPermissions = [ReviewToolPermission.All, ReviewToolPermission.CareLabelExpert, ReviewToolPermission.CareLabelAll];
export const TextReviewPermissions = [ReviewToolPermission.All, ReviewToolPermission.Text, ReviewToolPermission.TextAll];
export const TextReviewExpertPermissions = [ReviewToolPermission.All, ReviewToolPermission.TextExpert, ReviewToolPermission.TextAll];
export const QualityCheckPermissions = [ReviewToolPermission.All, ReviewToolPermission.ProductQuality, ReviewToolPermission.ProductQualityAll];
export const QualityCheckExpertPermissions = [ReviewToolPermission.All, ReviewToolPermission.ProductQualityExpert, ReviewToolPermission.ProductQualityAll];
export const ClearingPermissions = [ReviewToolPermission.All, ReviewToolPermission.Clearing];
export const AttributeQualityPermissions = [ReviewToolPermission.All, ReviewToolPermission.AttributeQuality, ReviewToolPermission.AttributeQualityAll];
export const AttributeQualityExpertPermissions = [ReviewToolPermission.All, ReviewToolPermission.AttributeQualityExpert, ReviewToolPermission.AttributeQualityAll];
export const ResolverPermissions = [ReviewToolPermission.All, ReviewToolPermission.Resolver, ReviewToolPermission.ResolverAll];
export const ResolverExpertPermissions = [ReviewToolPermission.All, ReviewToolPermission.ResolverExpert, ReviewToolPermission.ResolverAll];
export const ReprocessPermissions = [ReviewToolPermission.All, ReviewToolPermission.Reprocess, ReviewToolPermission.ReprocessAll];
export const ReprocessExpertPermissions = [ReviewToolPermission.All, ReviewToolPermission.ReproccessExpert, ReviewToolPermission.ReprocessAll];